import { useUserLanguageFromServer } from './locale/useUserLanguage';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useCMSContent } from './useCMSHome/useCMSContent';
import { IContentPageData } from '../interfaces/content/IContentPage';

interface IUseGetCMSContent {
    slug?: string;
    locale?: string;
}

export const useGetCMSContent = (options?: IUseGetCMSContent) => {
    const router = useRouter();

    const language = useUserLanguageFromServer();

    const slug = useMemo(() => {
        if (options?.slug) {
            return options.slug;
        }

        if (router.query['slug']) {
            return router.query['slug'] as string;
        }

        return '';
    }, [options?.slug, router.query]);

    const localeToUse = useMemo((): string => {
        if (options?.locale) {
            return options.locale;
        }

        // 1. Use given locale
        if (router.query['locale']) {
            return router.query['locale'] as string;
        }

        // 2. Use language setting
        if (language) {
            if (language === 'default') {
                return 'en';
            }

            return language;
        }

        return 'en';
    }, [language, options?.locale, router.query]);

    const { data, isLoading } = useCMSContent(slug, localeToUse);
    const { data: dataEN } = useCMSContent(slug, 'en');

    const dataFinal = useMemo((): IContentPageData | null => {
        // TODO Refactor Interface shift from GQL
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        if (data?.data?.pages?.data[0] === undefined) {
            // @ts-ignore
            // eslint-disable-next-line @typescript-eslint/no-unsafe-return
            return dataEN?.data?.pages?.data[0]?.attributes ?? null;
        }

        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return data.data.pages.data[0].attributes;
    }, [data, dataEN]);

    const isPublic = useMemo(() => {
        if (!dataFinal) return false;

        // TODO Refactor Interface shift from GQL
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return dataFinal.isPublic ?? false;
    }, [dataFinal]);

    const widgets = useMemo(() => {
        if (!dataFinal) return [];

        // TODO Refactor Interface shift from GQL
        // @ts-ignore
        return dataFinal.widgets;
    }, [dataFinal]);

    return { dataFinal, isLoading, isPublic, widgets };
};
