import React from 'react';

interface ISkeletonLine {
    height?: 'small' | 'large';
}

export const SkeletonLine: React.FC<ISkeletonLine> = (props) => {
    const { height = 'small' } = props;

    return (
        <>
            <div
                className={`relative block animate-pulse bg-neutral-500/10 ${
                    height === 'small' ? 'h-10' : 'h-32'
                }`}
            />
        </>
    );
};
